<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Fonction digitale" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="8" lg="10" md="10" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter une fonction digitale</div>
              </v-row>
            </v-card-title>

            <!-- le nom -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">nom digital</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field
                    placeholder="Le nom digital"
                    dense
                    v-model="name"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le service -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">service</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner un service"
                    :items="services"
                    item-text="name"
                    item-value="id"
                    no-data-text="aucun service"
                    v-model="serviceId"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- la définition -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="start">
                  <div class="label-field capitalize">définition</div>
                </v-col>
                <v-col cols="8" align-self="start">
                  <v-textarea
                    placeholder="La définition"
                    dense
                    auto-grow
                    v-model="definition"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- la description -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="start">
                  <div class="label-field capitalize">description</div>
                </v-col>
                <v-col cols="8" align-self="start">
                  <v-textarea
                    placeholder="La description"
                    dense
                    auto-grow
                    v-model="description"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- support -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">support</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-autocomplete 
                  :items="supports" 
                  v-model="isSupport"
                  no-data-text="aucune donnée"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- id drive -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">id drive</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field
                    placeholder="id drive"
                    dense
                    v-model="idDrive"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="4" justify="center" align-self="start">
                  <div class="label-field capitalize mt-2">
                    les rubriques spécifiques
                  </div>
                </v-col>
                <v-col cols="8" align-self="start">
                  <div v-for="(rubric, i) in associateRubrics" :key="i">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                    >
                      <v-col cols="4" class="px-1">
                        <div>
                          {{ rubric.name }}
                        </div>
                      </v-col>

                      <v-col cols="3" class="px-1">
                        <div>
                          {{ rubric.context }}
                        </div>
                      </v-col>

                      <v-col cols="4" class="px-1">
                        <v-text-field
                          placeholder="L'id drive"
                          v-model="rubric.idDrive"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="1" class="px-1">
                        <v-btn
                          icon
                          color="primary"
                          @click="removeAssociateRubric(rubric)"
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters align="center" justify="space-between">
                      <v-col cols="4" class="px-1">
                        <v-autocomplete
                          v-model="selectedRubric"
                          :items="availableRubrics"
                          item-text="name"
                          return-object
                          placeholder="Associer une rubrique"
                          hint="Associer une rubrique puis appuyer sur le bouton '+'"
                          clearable
                          no-data-text="aucune rubrique"
                          v-on:keyup.enter="addRubric(selectedRubric)"
                        >
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="3" class="px-2">
                        <v-autocomplete
                          v-model="selectedContext"
                          :items="rubricsContexts"
                          item-text="label"
                          return-object
                          placeholder="Associer un contexte"
                          hint="Associer un contexte"
                          clearable
                          no-data-text="aucun contexte"
                          v-on:keyup.enter="addRubric(selectedRubric)"
                        >
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="4" class="px-1">
                        <v-text-field
                            placeholder="L'id drive"
                            v-model="iddriveRubric"
                            class="px-1"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="1" align="end">
                        <v-btn
                          color="primary"
                          icon
                          @click="addRubric(selectedRubric)"
                          :disabled="!selectedRubric"
                          ><v-icon>mdi-plus</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { FonctionService } from "@/service/sfr/function_service.js";
import ServicesService from "@/service/sfr/services_service.js";
import RubricService from "@/service/sfr/rubrics_service.js";
import { RubricsContextService } from "@/service/sfr/rubrics_context_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import { trimString } from "@/tools/string_tool.js";

export default {
  name: "AddFunction",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      serviceFunction: null,
      serviceServices: null,
      serviceRubrics: null,
      serviceRubricsContext: null,

      /**La donnée originale */
      source: null,

      /**le nom */
      name: null,

      /**identifiant du service */
      serviceId: null,

      /**la définition de la fonction */
      definition: null,

      /**la description de la fonction */
      description: null,

      /**est-ce une fonction support? */
      isSupport: false,

      /**identifiant du dossier drive */
      idDrive: null,

      /**les rubriques associées */
      associateRubrics: [],

      /** toutes les rubriques */
      allRubrics: [],

      /**la rubrique sélectionnée pour l'ajout */
      selectedRubric: null,
      /** l'iddrive de la rubrique */
      iddriveRubric: null,

      /** les services disponibles pour la fonction */
      services: [],

      selectedContext: null,
      /** La liste des contextes des rubriques */
      rubricsContexts: [],

      /** les choix pour le support */
      supports: [
        { text: "SUPPORT", value: true },
        { text: "NON SUPPORT", value: false },
      ],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        this.services = await this.serviceServices.getAllServices();

        this.associateRubrics = [];

        // Récupération de l'ensemble des contexte de rubriques
        this.rubricsContexts = [];
        this.rubricsContexts = await this.serviceRubricsContext.getAll();

        /** +++++++ SUPPRESSION DU TUPLE NOMMÉ " RIEN" +++++ */
        // this.rubricsContexts.splice(
        //   this.rubricsContexts.findIndex((c) => c.label == "rien"),
        //   1
        // );
        /** ++++++++++++++++++++++++++++++++ */

        this.allRubrics = await this.serviceRubrics.getAllRubrics();

        for (let r of this.allRubrics) {
          r.idDrive = null;
        }
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**retirer une rubrique associée */
    removeAssociateRubric(rubric) {
      let index = this.associateRubrics.findIndex((r) => r.id == rubric.id);
      this.associateRubrics.splice(index, 1);
    },
    /**Ajouter une rubrique associée */
    addRubric(rubric) {
      if (!rubric) return;

      rubric.idDrive = this.iddriveRubric;
      rubric.context = this.selectedContext ? this.selectedContext.label : "";
      this.associateRubrics.push(rubric);

      this.selectedRubric = null;
      this.selectedContext = null;
      this.iddriveRubric = null;
    },
    async save() {
      let success = true;

      let func = null;

      // enregistrement de la partie fonction
      if (this.functionhasChanged) {
        try {
          let entity = {};
          entity.name = this.name;
          entity.serviceId = this.serviceId;
          entity.definition = this.definition;
          entity.description = this.description;
          entity.isSupport = this.isSupport;
          entity.idDrive = this.idDrive;

          func = await this.serviceFunction.create(entity);
        } catch (error) {
          success = false;
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de la fonction : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }

      // enregistrement de la partie rubriques
      if (this.rubricsHaveChanged && success) {
        //les rubriques à ajouter
        try {
          //on ajoute les rubriques à la fonction
          for (let i = 0; i < this.associateRubrics.length; i++) {
            await this.serviceFunction.addSpecificRubricToFunction(
              func.id,
              this.associateRubrics[i].id,
              { 
                context: this.associateRubrics[i].context,
                idDrive: this.associateRubrics[i].idDrive, 
              },
            );
          }
        } catch (error) {
          success = false;
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement des rubriques : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }

      if (success) {
        this.$router.go(-1);
      }
    },
    foo() {
      let entity = {};
      entity.name = this.name;
      entity.serviceId = this.serviceId;
      entity.definition = this.definition;
      entity.description = this.description;
      entity.isSupport = this.isSupport;
      entity.idDrive = this.idDrive;

      // console.log(JSON.stringify(entity, null, 4));
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.name = trimString(this.name);
    },
  },
  computed: {
    canSave() {
      try {
        if (!this.name || this.name == "") {
          return false;
        }

        if (!this.serviceId || this.serviceId == "") {
          return false;
        }

        let changed = false;

        if (this.functionhasChanged) {
          changed = true;
        }

        if (this.rubricsHaveChanged) {
          changed = true;
        }

        return changed;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    availableRubrics() {
      let availables = [];

      let ids = this.associateRubrics.map((r) => r.id);

      availables = this.allRubrics.filter((r) => !ids.includes(r.id));

      return availables;
    },

    /**retourne true si la fonction est modifiée (ne concerne pas les rubriques: voir rubricsHaveChanged)  */
    functionhasChanged() {
      let changed = false;

      if (this.name) {
        changed = true;
      }

      if (this.serviceId) {
        changed = true;
      }

      if (this.definition) {
        changed = true;
      }

      if (this.description) {
        changed = true;
      }

      if (this.isSupport) {
        changed = true;
      }

      if (this.idDrive) {
        changed = true;
      }

      return changed;
    },
    /** retourne true si les rubriques ont changés */
    rubricsHaveChanged() {
      return this.associateRubrics.length > 0;
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.serviceFunction = new FonctionService(this.$api.getFunctionApi());
    this.serviceServices = new ServicesService(this.$api);
    this.serviceRubrics = new RubricService(this.$api);
    this.serviceRubricsContext = new RubricsContextService(this.$api.getRubricsContextApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.functionId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>